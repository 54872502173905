@font-face {
  font-family: 'PF Textbook';
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: Frutiger, "Frutiger Linotype", "dejavu sans", "helvetica neue", arial, sans-serif;
  color:#111;
  background-color: #000;
  height: 100%;
  overflow: hidden;
}

a{
  color:#980000;
  text-decoration: none;
}

noscript table{
  width:100%;
  height:100%;
  text-align: center;
  font-weight:bold;
  font:1.5em; 
  line-height:1.5em;
  color:#fff;
}

#pano{
  width:100%;
  height:100%;
  z-index:3;
}

#welcome{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:block;
  overflow:auto;
  background-image: url(../img/acropolis-vt-bg.jpg);
  background-repeat: repeat;
  background-position: center;
  background-size:cover;
  z-index: 10;
}

@media( max-width: 768px ){
  #welcome{
    background-position: center -130px;
  }
}

@media( max-width: 420px ){
  #welcome{
    background-position: center 0;
  }
}

.land-page,
.land-page * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.land-page{
  position:relative;
  width:760px;
  max-width:80%;
  margin:8.6em auto 0;
  font-size:14px;
  font-weight:400;
  line-height: 1.4em;
  font-family: 'PF Textbook', 'Open Sans', sans-serif;
}

.land-page-top{
  width:372px;
  max-width:100%;
  margin-left:17.11%;
}

@media( max-width: 991px ){
  .land-page{
    max-width:90%;
  }

  .land-page-top{
    margin-left:10%;
  }
}


@media( max-width: 767px ){

  .land-page{
    margin-top:4em;
  }

  .land-page-top{
    margin-left:0%;
  }

}

@media( max-width: 680px ){
  .land-page{
    margin-top:2.5em;
  }
}

@media( max-width: 570px ){
  
  .land-page{
    margin-top:1.5em;
  }

  .land-page-top{
    max-width:64%;
  }
}

@media( max-width: 480px ){

  .land-page{
    margin-top:3.5em;
  }

  .land-page-top{
    max-width:81%;
  }
}

@media( max-width: 380px ){

  .land-page{
    margin-top:2.5em;
  }

  .land-page-top{
    max-width:81%;
  }

  .app-desc{
    margin-top:0px !important;
  }
}

.logo{
  position: relative;
  max-width: 100%;
  display:inline-block; 
}

.logo img{
  display: block;
}

.intro-text{
  margin-top:10px;
  color:#58351d;
  text-align: justify;
}

.begin-buttons-wrapper{
  position:relative;
  float:left;
  width:100%;
  display:inline-block;
  padding-top:16px;
}

.begin-buttons-wrapper a{
  position:relative;
  float:left;
  display:inline-block;
  height:42px;
  line-height:44px;
  margin-bottom: 10px;
  font-size: 20px;
  text-align:center;
  text-decoration: none;
  text-indent: -9999px;
  color:#76b1fc;
  border:1px solid #fff;
  background-color:#fff;
  background-position:center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.begin-buttons-wrapper a:hover{
  color:#58351d;
}

.begin-txt-button{
  width:138px;
  margin-right:7px;
  background-image: url(../img/enarxi.png);
}

.begin-txt-button:hover{
  background-image: url(../img/enarxi-hover.png);
}

.active-en .begin-txt-button{
  background-image: url(../img/begin.png);
}

.active-en .begin-txt-button:hover{
  background-image: url(../img/begin-hover.png); 
}

.begin-go-button{
  width:92px;
  background-image: url(../img/arrow-go.png);
}

.begin-go-button:hover{
  background-image: url(../img/arrow-go-hover.png);
}

@media( max-width: 400px ){

  .begin-buttons-wrapper a.begin-go-button{
    display:none;
  }

}

.language-buttons{
  position:relative;
  display: inline-block;
  list-style: none;
  margin:12px 0 17px;
}

.language-buttons li{
  position:relative;
  width:110px;
  max-width: 50%;
  float:left;
  display:inline-block;
  padding-right:7px;
  padding-bottom: 10px;
}

.language-buttons li:last-child{
  padding-right:0;
}

.language-buttons li a{
  display:inline-block;
  width:100%;
  height: 38px;
  line-height:40px;
  font-size: 15px;
  text-align:center;
  text-indent: -9999px;
  color:#fff;
  border-width: 1px;
  border-style: solid;
  background-position:center;
  background-repeat: no-repeat;
}

.language-buttons li a:hover{

}

.lang-gr{
  border-color: #fff;
  background-color:#58351d;
  background-image: url(../img/gr.png);
}

.lang-gr:hover{
  border-color: #58351d;
  background-color:#fff;
  background-image: url(../img/gr-hover.png);
}

.active-gr .lang-gr{
  border-color: #fff;
  background-color:#93c0fd;
  background-image: url(../img/gr-active.png);
}

.lang-en{
  border-color: #fff;
  background-color:#58351d;
  background-image: url(../img/en.png);
}

.lang-en:hover{
  border-color: #58351d;
  background-color:#fff;
  background-image: url(../img/en-hover.png);
}

.active-en .lang-en{
  border-color: #fff;
  background-color:#93c0fd;
  background-image: url(../img/en-active.png);  
}

.app-desc{
  position:relative;
  width:100%;
  margin:38px auto;
  padding:20px 9.21%;
  color:#434449;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: url(../img/app-desc-bg.png) repeat;
  background:rgba(255, 255, 255, 0.8);
}

.app-desc a{
  font-weight:bold;
  color:#58351d;
}

.app-desc a:hover{
  color:#980000;
  text-decoration:underline;
}

.app-desc-title{
  display: block;
  margin:0 0 20px;
  padding-top:16px;
  font-size:23px;
  line-height: 1.25em;
  font-weight:500;
  color:#58351d;
}

.app-desc p,
.app-desc ul{
  margin-bottom:20px;
}

.app-desc ul{
  list-style: none;
}

.app-desc-text{
  display:inline-block;
  margin-bottom:20px;
}

.app-desc-text p:last-child{
  margin-bottom:0;
}

.app-desc-text.left-border{
  font-size:15px;
  padding-left:13px;
  color:#58351d;
  border-left:10px solid #58351d;
}

.app-supporters{
  width:100%;
  display:inline-block;
  margin:50px 0 30px;
}

.app-supporters a{
  position:relative;
  width:auto;
  float:left;
  display:inline-block;
  margin:10px 10px 10px 0;
}

.app-supporters img{
  display:block;
}